<template>
  <div>
    <v-card-text v-if="!isConfirmed" class="text-center py-12">
      Davetiyeniz geçersiz. <br />
      Lütfen sistem yöneticiyle iletişime geçiniz.
    </v-card-text>

    <v-form v-if="isConfirmed" ref="form" @submit.prevent="confirmInvitation()">
      <v-alert
        dark
        v-if="alert"
        :type="error ? 'error' : 'success'"
        style="border-radius: 0;"
        ><span v-html="message"
      /></v-alert>
      <v-card-text>
        <div class="d-flex justify-space-around">
          <v-text-field
            id="firstName"
            v-model="form.firstName"
            label="Ad"
            name="firstName"
            prepend-icon="person"
            type="text"
            required
            :rules="firstNameRules"
          />
          <v-text-field
            id="lastName"
            v-model="form.lastName"
            label="Soyad"
            name="lastName"
            type="text"
            required
            :rules="lastNameRules"
            class="ml-4"
          />
        </div>
        <v-text-field
          id="email"
          v-model="form.email"
          label="e-Posta"
          name="email"
          prepend-icon="alternate_email"
          type="text"
          required
          :rules="emailRules"
          disabled
        />
        <div class="d-flex justify-space-around">
          <v-text-field
            id="password"
            v-model="form.password"
            label="Parola"
            name="password"
            prepend-icon="lock"
            type="password"
            required
            min="6"
            :rules="passwordRules"
          />
          <v-text-field
            id="passwordConfirm"
            v-model="form.passwordConfirm"
            label="Parola Onayı"
            name="passwordConfirm"
            type="password"
            required
            min="6"
            :rules="passwordConfirmRules"
            class="ml-4"
          />
        </div>
      </v-card-text>
      <v-card-actions class="mx-2">
        <v-btn color="primary" type="submit" dark rounded block class="mb-2"
          >KAYIT OL</v-btn
        >
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  metaInfo: {
    title: "Supervisor Davetiyesi"
  },
  data: () => ({
    invitation: null,
    form: {
      firstName: "",
      lastName: "",
      password: "",
      passwordConfirm: "",
      email: ""
    },
    isConfirmed: false,
    alert: false,
    message: "",
    error: false
  }),
  computed: {
    invitationId() {
      return this.$route.params.id;
    },
    firstNameRules() {
      return [v => !!v || "Lütfen adınızı yazınız"];
    },
    lastNameRules() {
      return [v => !!v || "Lütfen soyadınızı yazınız"];
    },
    emailRules() {
      return [
        v => !!v || "Lütfen e-postanızı yazınız",
        v => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
      ];
    },
    passwordRules() {
      return [
        v => !!v || "Lütfen parolanızı yazınız",
        v => v.length >= 6 || "En az 6 karakter olmalıdır"
      ];
    },
    passwordConfirmRules() {
      return [
        v => !!v || "Lütfen parolanızı onaylayınız",
        v => v.length >= 6 || "En az 6 karakter olmalıdır",
        v => v === this.form.password || "Parola onaylanmadı"
      ];
    }
  },
  methods: {
    validate() {
      const form = this.$refs.form;
      return form.validate();
    },
    reset() {
      const form = this.$refs.form;
      form.reset();
    },
    resetValidation() {
      const form = this.$refs.form;
      form.resetValidation();
    },
    async getInvitation() {
      const invitationDoc = await firebase
        .firestore()
        .doc("businessSupervisorInvitations/" + this.invitationId)
        .get();

      if (!invitationDoc.exists) {
        // Davetiye var mı?
        this.isConfirmed = false;
      } else {
        const invitation = invitationDoc.data();
        this.invitation = invitation;
        this.invitation.id = invitationDoc.id;

        // Davetiyedeki kullanıcı bilgilerini formda göster
        this.form.firstName = invitation.firstName;
        this.form.lastName = invitation.lastName;
        this.form.email = invitation.email;

        if (invitation.dateOpened) {
          // Davetiye daha önce açılmış mı?
          this.isConfirmed = false;
        } else {
          this.isConfirmed = true;
        }
      }
    },
    async confirmInvitation() {
      if (this.validate()) {
        try {
          // Kullanıcı kaydı oluştur.
          const newUserRes = await firebase
            .auth()
            .createUserWithEmailAndPassword(
              this.form.email,
              this.form.password
            );

          // Update user profile
          newUserRes.user.updateProfile({
            displayName: `${this.form.firstName} ${this.form.lastName}`
          });

          // Davetiyeyi onayla
          await firebase
            .firestore()
            .doc("businessSupervisorInvitations/" + this.invitation.id)
            .update({
              uid: newUserRes.user.uid,
              isAccepted: true,
              dateOpened: firebase.firestore.FieldValue.serverTimestamp()
            });

          this.error = false;
          this.alert = true;
          this.message =
            "Kullanıcı hesabınız başarıyla oluşturuldu. Lütfen bekleyiniz...";

          // Oturum aç
          const user = {
            id: newUserRes.user.uid,
            displayName: `${this.form.firstName} ${this.form.lastName}`,
            email: newUserRes.user.email,
            roles: [] // Roller kurumsal kullanıcı kaydından alınacak
          };
          this.$store.dispatch("auth/signIn", user);

          // Kullanıcıyı varsayılan sayfaya yönlendir
          const returnRoute = { name: "supervisor-home" };

          setTimeout(() => {
            this.$router.push(returnRoute);
          }, 3000);
        } catch (err) {
          let msg = "";
          switch (err.code) {
            case "auth/email-already-in-use":
              msg = `Bu e-posta adresi zaten sistemimizde kayıtlı. <br/> Lütfen bekleyin! Giriş sayfasına yönlendiriliyorsunuz...`;

              // Davetiyeyi uygulama belleğine kaydet
              this.$store.dispatch(
                "auth/saveSupervisorInvitation",
                this.invitation
              );

              // Giriş sayfasına yönlendir
              setTimeout(() => {
                this.$router.push({ name: "signin" });
              }, 3000);

              break;
            default:
              msg = `HATA! Kullanıcı kaydı oluşturulamadı. Lütfen sistem yöneticisiyle iletişime geçiniz.`;
          }

          this.error = true;
          this.alert = true;
          this.message = msg;
        }
      }
    }
  },
  async mounted() {
    await this.getInvitation();

    this.$emit("setTitle", "Supervisor Davetiyesi");
  }
};
</script>

<style lang="scss" scoped></style>
